/* 
 * @Author: 曹俊杰 
 * @Date: 2020-08-25 17:45:11
 * @Module: 封禁管理列表
 */
 <template>
  <div>
    <div class="header">
      <h6>封禁管理</h6>
    </div>
    <el-card style="margin-top:20px">
      <el-row :gutter="20">
        <el-form :inline="true"
                 :size="'small'"
                 :model="formInline"
                 class="demo-form-inline">
          <el-col :span="6">
            <el-form-item label="快手号:">
              <el-input v-model="formInline.tick_account"
                        placeholder="请输入要查询的快手号"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="类型:">
              <el-select v-model="formInline.type"
                         placeholder="选择要查询的封禁类型"
                         clearable>
                <el-option label="直播"
                           :value="0"></el-option>
                <el-option label="开小号"
                           :value="1"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col>
            <el-form-item>
              <el-button type="primary"
                         icon="el-icon-search"
                         @click="req_list(true)">搜索</el-button>
            </el-form-item>
          </el-col>
        </el-form>
      </el-row>
      <tablePage :tableData="tableData"
                 :currentPage="formInline.page"
                 :height="tableHeight"
                 @current-change="currentChange"
                 v-loading="loading"
                 :total="total"
                 ref="tableList"
                 id="table">
        <el-table-column label="主播信息"
                         min-width="300">
          <template slot-scope="scope">
            <div class="base-wrap">
              <el-avatar :size="64"
                         :src="scope.row.avatar"></el-avatar>
              <div>
                <p>{{ scope.row.nick_name }}</p>
                <p>快手号：{{ scope.row.tick_account }}</p>
                <p>快手ID：{{ scope.row.tick_id }}</p>
              </div>
            </div>
          </template>
        </el-table-column>

        <el-table-column label="主播/经纪人（姓名）"
                         min-width="180">
          <template slot-scope="scope">
            <p>主播：{{ scope.row.name }}</p>
            <p>经纪人：{{ scope.row.broker_name }}</p>
          </template>
        </el-table-column>

        <el-table-column label="处罚信息"
                         min-width="180">
          <template slot-scope="scope">
            <p>
              类型：
              <span style="color:#509DED">{{scope.row.type===0?'直播':scope.row.type===1?'开小号':'-'}}</span>
            </p>
            <p>
              结果：
              {{scope.row.result}}
            </p>
          </template>
        </el-table-column>

        <el-table-column label="处罚理由"
                         min-width="210">
          <template slot-scope="scope">
            <el-tooltip effect="dark"
                        placement="top-start">
              <div slot="content"
                   style="max-width:200px;">{{scope.row.detail||scope.row.reason}}</div>
              <div style="cursor: pointer;">
                <span>{{ scope.row.reason }}</span>
                &nbsp;
                <span class="icon iconfont icon-wenhao"
                      style="color:#509DED"
                      v-if="scope.row.detail"></span>
              </div>
            </el-tooltip>
          </template>
        </el-table-column>

        <!-- <el-table-column label="申诉状态/结果"
                         min-width="180">
          <template slot-scope="scope">
            <p>状态：{{ scope.row.appeal_status }}</p>
            <p>结果：{{scope.row.appeal_result}}</p>
          </template>
        </el-table-column> -->

        <el-table-column prop="punish_time"
                         min-width="90"
                         label="处罚时间"></el-table-column>
        <el-table-column min-width="135"
                         label="处罚结束时间">
          <template slot-scope="scope">
            {{scope.row.punish_end_time || "-"}}
          </template>
        </el-table-column>
      </tablePage>
    </el-card>
  </div>
</template>
 <script>
//  document.body.clientHeight
import tablePage from "components/tablePage";
import api from "@/api";
import tableHeight from 'mixins/tableHeight'
export default {
  data () {
    return {
      loading: true,
      formInline: {
        tick_account: null,
        page: 1,
        type: null
      },
      tableData: [],
      total: 10,
      tableHeight: 1000,
    };
  },
  components: {
    tablePage,
  },
  mixins: [tableHeight],
  created () {
    this.req_list(true);
  },
  mounted () {
  },
  methods: {
    async _initState (params = {}) {
      this.loading = true;
      const {
        status_code,
        meta: {
          pagination: { total },
        },
        message,
      } = await api.bannedListApi(params);
      if (status_code === 200) {
        this.total = total;
        this.tableData = message;
        this.loading = false;
      }
    },
    req_list (refresh) {
      if (refresh) this.formInline.page = 1;
      this._initState(this.formInline);
    },
    currentChange (page) {
      this.formInline.page = page;
      this.req_list();
    }
  },
  computed: {},
};
</script>
<style lang="less" scoped>
@import "./style.less";
</style>